import styles from './style.module.scss'
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();

  const handleJoinMeeting = async (e) => {
    e.preventDefault();
  };

  const handleCreateMeeting = async (e) => {
    e.preventDefault();
    navigate('/meeting');
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleJoinMeeting}>
        <input type={'text'} placeholder={'Code meeting'} name={'meetingId'} />
        <input type={'submit'} value={'Rejoindre'} />
      </form>
      <div className={styles.separator}/>
      <form onSubmit={handleCreateMeeting}>
        <input type={'submit'} value={'Créer un nouveau meeting'} />
      </form>
    </div>
  )
}
