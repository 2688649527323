import Router from './routes';
import { Provider as AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
}

export default App;
