import OT from '@opentok/client';

import styles from './style.module.scss';

import { ReactMic } from '../../components/react-mic/src';
import Output from '../../components/output/Output';
import InputSelect from '../../components/inputSelect/InputSelect';
import { useCallback, useEffect, useRef, useState } from 'react';
import apiSocket from '../../services/socket';

const API_KEY = 'aYOMAen6JmD8CAo06Ui70CnFRHB03OUb';
const vonageApiKey = '47730731';

function Meeting() {
  const [ user, setUser ] = useState({ id: 0, name: '' });
  const [ sessionId, setSessionId ] = useState(null);
  const [ token, setToken ] = useState(null);
  const [ connected, setConnected ] = useState(false);

  const [ device, setDevice ] = useState(null);
  const [ language, setLanguage ] = useState(null);
  const [ recording, setRecording ] = useState(false);

  const [ result, setResult ] = useState({ transcript: '', language: '' });
  const [ newLine, setNewLine ] = useState(null);
  const [ lines, setLines ] = useState([]);

  const [ audios, setAudios ] = useState([]);
  const [ audioPlayerReady, setAudioPlayerReady ] = useState(true);

  const smSocket = useRef({});
  const buffer = useRef(new Blob());
  const [ recognize, setRecognize ] = useState(false);

  const handleVonageError = (error) => {
    if (error) {
      alert(error.message);
    }
  }

  const initializeVonageSession = () => {
    if (!token || !sessionId) return;
    const session = OT.initSession(vonageApiKey, sessionId);

    // Subscribe to a newly created stream
    session.on('streamCreated', function(event) {
      session.subscribe(event.stream, 'subscriber', {
        insertMode: 'append',
        width: '100%',
        height: '100%',
      }, handleVonageError);
    });

    // Create a publisher
    var publisher = OT.initPublisher('publisher', {
      insertMode: 'append',
      width: '100%',
      height: '100%'
    }, handleVonageError);

    // Connect to the session
    session.connect(token, function(error) {
      // If the connection is successful, initialize a publisher and publish to the session
      if (error) {
        handleVonageError(error);
      } else {
        session.publish(publisher, handleVonageError);
      }
    });
  }
  useEffect(initializeVonageSession, [ token, sessionId ]);

  const pushLine = useCallback((text, userId, userName) => {
    const newLines = [ ...lines ];
    if (lines.length > 0 && newLines[lines.length - 1].userId === userId) {
      newLines[lines.length - 1].text += (' ' + text);
    } else {
      newLines.push({
        text: `${userName} : ${text}`,
        userId,
      })
    }
    setLines(newLines);
  }, [ lines, setLines ]);

  useEffect(() => {
    if (!newLine) return;
    pushLine(newLine.text, newLine.userId, newLine.userName);
  }, [ newLine ]);

  const playAudio = (data) => {
    const url = `${process.env.REACT_APP_API_URL}/media/${data.uuid}.mp3`;
    window.audio = new Audio();
    window.audio.src = url;
    window.audio.play();
  }

  useEffect(() => {
    if (audios.length > 0 && audioPlayerReady) {
      const audio = audios[0];
      setAudios((audios) => (audios.slice(1)));
      setAudioPlayerReady(false);
      playAudio(audio);
      setTimeout(() => setAudioPlayerReady(true), audio.duration * 1000 - 100);
    }
  }, [ audios, audioPlayerReady]);

  const registerSocketEvents = () => {
    apiSocket.on('connect', () => {
      console.log('apiSocket connected');
      setConnected(true);
    });

    apiSocket.on('sendId', (data) => {
      setUser((user) => ({
        ...user,
        id: data.id,
      }));
      setRecording(true);
    })

    apiSocket.on('sendToken', (data) => {
      setToken(data.token);
      setSessionId(data.sessionId);
    });

    apiSocket.on('sendVoice', (data) => {
      setAudios((audios) => ([ ...audios, data ]));
    });

    apiSocket.on('sendText', (data) => {
      setNewLine({ text: data.text, userId: data.userId, userName: data.userName });
    })
    apiSocket.on('disconnect', (data) => {
      console.log('disconnected', data);
      setConnected(false);
    });

    return () => apiSocket.removeAllListeners();
  }

  useEffect(registerSocketEvents, []);

  const initSmSocket = async () => {
    try {
      const res = await fetch('https://mp.speechmatics.com/v1/api_keys?type=rt', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`,
        },
        body: JSON.stringify({
          ttl: 3600,
        }),
      });
      const { key_value } = await res.json();

      smSocket.current = new WebSocket(`wss://eu2.rt.speechmatics.com/v2/${language}?jwt=${key_value}`);
      smSocket.current.onerror = (event) => {
        console.log('socket error', event);
      };
      smSocket.current.onclose = (event) => {
        console.log('socket closed', event);
        // setTimeout(() => window.location.reload(), 2000);
      };
      smSocket.current.onopen = (event) => {
        console.log('socket open', event);
        smSocket.current.send(JSON.stringify({
          message: 'StartRecognition',
          audio_format: {
            type: 'raw',
            encoding: 'pcm_s16le',
            sample_rate: 48000,
          },
          transcription_config: {
            language: language,
            max_delay: 5,
            max_delay_mode: 'flexible',
          },
        }));
      };

      smSocket.current.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);
        if (data.message === 'AddTranscript') {
          const { transcript } = data.metadata;
          if (transcript) {
            setResult({ transcript, language });
          }
        }
      });

    } catch (err) {
      console.log('Error initSmSocket', err)
    }
  }

  const translateResult = async (result) => {
    const { transcript: text, language } = result;
    if (!text) return;

    apiSocket.emit('text', { text })

    setNewLine({ text, userId: user.id, userName: user.name });
  }

  useEffect(() => {
    translateResult(result);
  }, [ result ]);

  const isSocketOpen = (socket) => {
    return (
      socket !== null &&
      socket !== undefined &&
      socket.readyState === 1
    );
  }

  const recognizeData = async () => {
    if (isSocketOpen(smSocket.current)) {
      const data = buffer.current;
      const chunk = data.slice(0, 16384);
      buffer.current = data.slice(16384);
      const rawData = await chunk.arrayBuffer();
      smSocket.current.send(rawData);
    }
  };

  useEffect(() => {
    if (recognize) {
      recognizeData();
      setRecognize(false);
    }
  }, [ recognize ]);

  const onData = (data) => {
    buffer.current = new Blob([buffer.current, data]);
    if (buffer.current.size >= 16384) {
      setRecognize(true);
    }
  }

  const reJoinRoom = () => {
    if (!token) return;
    apiSocket.emit('getId', { name: user.name, language });
  }

  useEffect(() => {
    if (connected) reJoinRoom();
  }, [ connected ])

  const joinRoom = () => {
    initSmSocket();
    apiSocket.emit('getToken');
    apiSocket.emit('getId', { name: user.name, language });
  }

  const leaveRoom = () => {
    setRecording(false);
    apiSocket.disconnect();
    smSocket.current.close();
    setToken(null);
    setSessionId(null);
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>iBridgePeople V4</h1>
        <span>v0.1.4</span>
      </div>
      <div className={styles.body}>
        {!connected && <div className={styles.notConnected}>
          <p className={styles.notConnectedText}>Not connected</p>
        </div>}
        <div className={styles.settings}>
          <InputSelect
            title={'Settings'}
            onLanguageSelected={setLanguage}
            onDeviceSelected={setDevice}
            onUserNameChange={(name) => setUser((user) => ({ ...user, name }))}
            userName={user.name}
          />
          <div className={styles.button + ' ' + (user.name.length === 0 ? styles.notReadyButton : '')} onClick={() => joinRoom(true)}>
            <p>{'Start'}</p>
          </div>
          {recording && <div className={styles.button + ' ' + styles.leaveButton} onClick={leaveRoom}>
            <p>{'Leave the room'}</p>
          </div>}
        </div>
        <Output lines={lines}/>
        <ReactMic
          record={recording}
          className={styles.reactMic}
          onData={onData}
          strokeColor="blue"
          backgroundColor="white"
          bufferSize={512}
          mimeType="audio/wav"
          deviceId={device}
          channelCount={2}
          echoCancellation={true}
          autoGainControl={true}
          noiseSuppression={true}
          sampleRate={48000}
        />
        <div id="videos" className={styles.videos}>
          <div id="subscriber" className={styles.subscriber}></div>
          <div id="publisher" className={styles.publisher}></div>
        </div>
      </div>
    </div>
  );
}

export default Meeting;
