import { useEffect, useRef } from 'react';
import styles from './Output.module.scss';

export default ({ lines, userId }) => {
  const endAnchor = useRef(null);

  useEffect(() => {
    endAnchor.current?.scrollIntoView({ behavior: 'smooth' });
  }, [ lines ]);

  return (
    <div className={styles.container}>
      {lines.map((l, i) => (
        <p key={i} className={styles.text + ' ' + (l.userId === userId ? styles.color0 : styles.color1)}>
          {l.text}
        </p>
      ))}
      <div ref={endAnchor}/>
    </div>
  );
}
