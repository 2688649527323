import AudioContext from './AudioContext'
import { MediaRecorder, register,  } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

let analyser
let audioCtx
let mediaRecorder
let chunks = []
let startTime
let stream
let mediaOptions
let onStartCallback
let onStopCallback
let onSaveCallback
let onDataCallback
let constraints
let channelCount

console.log('getUserMedia', navigator.getUserMedia, navigator.mediaDevices);
navigator.getUserMedia = (navigator.getUserMedia
                          || navigator.webkitGetUserMedia
                          || navigator.mozGetUserMedia
                          || navigator.msGetUserMedia
                          || navigator.mediaDevices?.getUserMedia);
console.log('getUserMedia', navigator.getUserMedia);

export class MicrophoneRecorder {
  constructor(onStart, onStop, onSave, onData, options, soundOptions) {
    const {
      echoCancellation,
      autoGainControl,
      noiseSuppression,
      deviceId,
      sampleRate,
    } = soundOptions

    onStartCallback = onStart
    onStopCallback = onStop
    onSaveCallback = onSave
    onDataCallback = onData
    mediaOptions = options

    constraints = {
      audio: {
        echoCancellation,
        autoGainControl,
        noiseSuppression,
        deviceId: { exact: deviceId },
        sampleRate,
      },
      video: false
    }
    this.connect();
  }

  connect = async () => {
    try {
      await register(await connect());
    } catch (err) {
      console.error('Error register', err);
    }
  }

  changeDeviceId = (newDeviceId) => {
    constraints.audio.deviceId = { exact: newDeviceId };
  }

  startRecording = () => {
    startTime = Date.now()

    if (mediaRecorder) {
      if (audioCtx && audioCtx.state === 'suspended') {
        audioCtx.resume()
      }

      if (mediaRecorder && mediaRecorder.state === 'paused') {
        mediaRecorder.resume()
        return
      }

      if (audioCtx && mediaRecorder && mediaRecorder.state === 'inactive') {
        mediaRecorder.start(50)
        const source = audioCtx.createMediaStreamSource(stream)
        source.connect(analyser)
        if (onStartCallback) { onStartCallback() }
      }
    } else if (navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then((str) => {
          stream = str
          const tracks = stream.getAudioTracks()
          const [track] = tracks

          track.applyConstraints(constraints)

          if (MediaRecorder.isTypeSupported(mediaOptions.mimeType)) {
            mediaRecorder = new MediaRecorder(str, mediaOptions)
          } else {
            mediaRecorder = new MediaRecorder(str)
          }

          if (onStartCallback) { onStartCallback() }

          mediaRecorder.onstop = this.onStop
          mediaRecorder.ondataavailable = (event) => {
            chunks.push(event.data)
            if (onDataCallback) {
              onDataCallback(event.data)
            }
          }

          audioCtx = AudioContext.getAudioContext()
          audioCtx.resume().then(() => {
            analyser = AudioContext.getAnalyser()
            mediaRecorder.start(50)
            const sourceNode = audioCtx.createMediaStreamSource(stream)
            sourceNode.connect(analyser)
          })
        })
    } else {
      alert('Your browser does not support audio recording')
    }
  }

  stopRecording() {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      mediaRecorder.stop()

      stream.getAudioTracks().forEach((track) => {
        track.stop()
      })
      mediaRecorder = null
      AudioContext.resetAnalyser()
    }
  }

  onStop() {
    const blob = new Blob(chunks, { type: mediaOptions.mimeType })
    chunks = []

    const blobObject = {
      blob,
      startTime,
      stopTime: Date.now(),
      options: mediaOptions,
      blobURL: window.URL.createObjectURL(blob)
    }

    if (onStopCallback) { onStopCallback(blobObject, channelCount) }
    if (onSaveCallback) { onSaveCallback(blobObject) }
  }
}
