import Select from 'react-select';
import { useEffect, useState } from 'react';
import styles from './InputSelect.module.scss';

export default ({ title, onDeviceSelected, onLanguageSelected, onUserNameChange, userName }) => {
  const [ devices, setDevices ] = useState([]);

  const getDevices = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    let devices = await navigator.mediaDevices.enumerateDevices();
    console.log('devices', devices);
    setDevices(devices.filter(d => d.kind === 'audioinput' && d.deviceId !== 'default').map(d => ({
      value: d.deviceId,
      label: d.label,
    })))
  }

  const languages = [
    { value: 'fr', label: 'Français' },
    { value: 'en', label: 'English' },
    { value: 'de', label: 'Deutsch' },
    { value: 'es', label: 'Espanol' },
    { value: 'it', label: 'Italiano' },
    { value: 'pt', label: 'Portugues' },
  ];

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <input className={styles.select} placeholder={'Name'} type={'text'} value={userName} onChange={(e) => onUserNameChange(e.target.value)} />
      <Select
        className={styles.select}
        onChange={({ value }) => {
          onDeviceSelected(value);
        }}
        defaultValue={devices[0]}
        options={devices}
        placeholder={'Input device'}
      />
      <Select
        className={styles.select}
        onChange={({ value }) => {
          onLanguageSelected(value);
        }}
        options={languages}
        placeholder={'Language'}
      />
    </div>
  )
};
