const post = async (path, body) => {
  const result = await fetch(`${process.env.REACT_APP_API_URL}/api${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  });
  console.log('result', result);
  const data = await result.json();
  console.log('data', data);
  if (result.status >= 400) {
    throw new Error(data.message);
  } else {
    return data;
  }

}

const login = async (email, password) => {
  return post('/auth/login', { email, password });
}

const register = async ({ firstname, lastname, email, password }) => {

}

export {
  login,
  register,
}
