import authForm from '../auth-form.module.scss';

export default function Register() {
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('value', e);
  }

  return (
    <div className={authForm.container}>
      <h1>Inscription</h1>
      <form onSubmit={handleSubmit}>
        <input type={'text'} name={'firstname'} placeholder={'Prénom'} />
        <input type={'text'} name={'lastname'} placeholder={'Nom'} />
        <input type={'text'} name={'email'} placeholder={'Email'} />
        <input type={'text'} name={'confirmEmail'} placeholder={'Confirmation d\'email'} />
        <input type={'password'} name={'password'} placeholder={'Mot de passe'} />
        <input type={'password'} name={'confirmPassword'} placeholder={'Confirmation de mot de passe'} />
        <input type={'submit'} value={'Créer mon compte'} />
      </form>
    </div>
  )
}
