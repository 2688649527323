import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import App from '../App';
import Meeting from './Meeting';
import Login from './auth/Login';
import Register from './auth/Register';
import { useAuth } from '../contexts/AuthContext';
import { useEffect } from 'react';
import Home from './Home';

const ProtectedRoute = ({ children }) => {
  const { jwt, loaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded && !jwt) navigate('/login', { replace: true });
  }, [ jwt, loaded, navigate ]);

  return children;
}

export default function Router() {
  return (
    <Routes>
      <Route path={'/login'} element={<Login/>}/>
      <Route path={'/register'} element={<Register />}/>
      <Route
        path={'/'}
        element={
          <ProtectedRoute>
            <Home/>
          </ProtectedRoute>
        }
      />
      <Route
        path={'/meeting'}
        element={
          <ProtectedRoute>
            <Meeting/>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
