import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const AuthContext = createContext({ loaded: false, jwt: null, user: null });

export function Provider({ children }) {
  const [ loaded, setLoaded ] = useState(false);
  const [ jwt, setJwt ] = useState(null);
  const [ user, setUser ] = useState(null);

  useEffect(() => {
    const rawStoredData = localStorage.getItem('AuthContext');
    if (!rawStoredData) {
      setLoaded(true);
      return;
    }
    const storedData = JSON.parse(rawStoredData);
    setJwt(storedData.jwt || null);
    setUser(storedData.user || null);
    setLoaded(true);
  }, []);

  useEffect(() => {
    localStorage.setItem('AuthContext', JSON.stringify({ jwt, user }));
  }, [ jwt, user ]);

  const value = useMemo(() => ({
    user,
    jwt,
    setUser,
    setJwt,
    loaded,
  }), [ user, jwt, loaded ]);
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext);
}
